<template>
  <div>
    <v-card width="100%" elevation="3" class="px-9 pt-10 pb-14 mb-12">
      <v-card-title style="font-size: 2.4rem; line-height: 3rem" class="pb-2">
        {{ organization_data.organization_short_name || organization_data.organization_full_name }}
      </v-card-title>
      <v-card-text class="pt-8 pb-3" style="font-size: 1.2rem; line-height: 1.8rem; width: 80%">
        {{ site_config.main_page.header_text }}
      </v-card-text>
      <v-card-text class="mt-4">
        <v-btn :color="accent_color" large class="py-3 px-4 mr-3"
               :to="{ name: 'SiteDebt', params: { organization_slug: $route.params.organization_slug } }"
               :dark="site_config.main_page.white_button_text"
        >
          Боржники
        </v-btn>
        <v-btn :color="accent_color" large class="py-3 px-8"
               :to="{ name: 'SiteContacts', params: { organization_slug: $route.params.organization_slug } }"
               :dark="site_config.main_page.white_button_text"
        >
          Контакти
        </v-btn>
      </v-card-text>
    </v-card>
    <div class="mt-6" v-if="notifications.length">
      <div style="font-size: 1.07rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 8px; text-transform: uppercase">Остані оголошення</div>
      <v-row>
        <v-col cols="12" sm="6" md="4"
          v-for="(notify, n) in notifications" :key="`notification-${n}`"
        >
          <v-card
              outlined
              height="100%"
          >
            <v-card-text>
              <div class="text-overline mb-2 d-flex"
                   :class="getStatusType(notify.status, 'color')"
              >
                <div style="font-size: .7rem">{{ getStatusType(notify.status) }}</div>
                <v-spacer></v-spacer>
                <div class="grey--text text--darken-1">
                  {{ notify.create_date | formatDate }}
                </div>
              </div>
              <div class="font-weight-bold mb-3 text-wrap" style="color: #2d2d2d; font-size: 1.04rem">
                {{ notify.title }}
              </div>
              <div style="font-size: .9rem">
                {{ notify.body }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-6" v-else>
      <div style="font-size: 1.07rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 8px; text-transform: uppercase">Остані оголошення</div>
      <v-row>
        <v-col cols="12" md="12">
          <v-card
              outlined
              height="100%"
          >
            <v-card-text
                class="text-center text-uppercase" style="font-size: 1.3rem; padding: 80px">
              На даний момент оголошення відсутні...
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-10" v-if="news.length">
      <div style="font-size: 1.07rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 8px; text-transform: uppercase">Остані новини</div>
      <v-row>
        <v-col cols="12" md="4" sm="12"
               v-for="(item, i) in news" :key="`news-${i}`"
        >
          <v-card height="100%">
            <v-card-text class="pa-4">
              <v-img
                  :src="getFile(item)"
                  aspect-ratio="1"
                  max-height="200"
                  height="auto"
              />
            </v-card-text>
            <v-card-text>
              <div class="font-weight-bold mb-3 text-wrap" style="color: #2d2d2d; font-size: 1rem">
                {{ item.title }}
              </div>
              <div style="height: 100px">
                {{ item.body.slice(0, 300) }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-chip label>
                {{ item.create_date | formatDate }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn :color="accent_color" text :to="{ name: 'SiteNewsView', params: { news_id: item.id, organization: $route.params.organization  } }">
                Детально
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-6" v-else>
      <div style="font-size: 1.07rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 8px; text-transform: uppercase">Остані новини</div>
      <v-row>
        <v-col cols="12" md="12">
          <v-card
              outlined
              height="100%"
          >
            <v-card-text
                class="text-center text-uppercase" style="font-size: 1.3rem; padding: 80px">
              На даний момент новини відсутні...
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import siteAPI from "@/utils/axios/personalSite/site"
import {BASE_URL_AXIOS} from "@/utils/axios";
import {getStatusType} from "@/utils/icons";

export default {
  name: "HomePage",
  computed: {
    ...mapGetters({
      organization_data: 'getSiteOrganization',
      site_config: 'getSiteConfig'
    }),
    accent_color() {
      return this.site_config.site_color || 'secondary'
    }
  },
  data() {
    return {
      notifications: [],
      news: [],
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    getStatusType,
    getFile(payload) {
      if (!payload.filepath) return `${this.publicPath}img/icons/no_image.png`
      return `${BASE_URL_AXIOS}/${payload.filepath}`
    }
  },
  created() {
    const organization_slug = this.$route.params.organization || ''
    if (organization_slug) {
      siteAPI.get_notification(organization_slug)
          .then(response => response.data)
          .then(data => {
            this.notifications = data

            siteAPI.get_news(organization_slug)
              .then(r => r.data)
              .then(data_ => {
                this.news = data_
              })

          })
          .catch(() => {
            this.$router.push({ name: 'NotFoundSite' })
          })
    }
  }
}
</script>

<style scoped>

</style>